<template>
  <a-form
    layout="horizontal"
    :model="formState"
    class="flex flex-wrap md:flex-nowrap gap-3 items-center justify-center"
    :rules="rules"
    ref="formRefFilter"
  >
    <a-form-item label="Buscar" class="mb-0 w-full md:w-48" name="search">
      <a-input v-model:value="formState.search" placeholder="Buscar por talla" />
    </a-form-item>

    <div class="flex">
      <button @click="applyFilters" type="submit" class="text-center px-3 ml-2">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";
import UsuariosApi from "@/api/user";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["fetchTalla"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);
    const { establecimiento } = store.state.user.usuario;

    const formState = reactive({
      search: store.getters["ninios/search2"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Nutricional", "Nutricional Graficas").catch((error) =>
        console.log(error)
      );
    });

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { search } = toRaw(formState);

          store.dispatch("ninios/setSearch2", search);
          emit("fetchTalla");
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Nutricional", "Filtros Talla niños", establecimiento).catch((error) =>
        console.log(error)
      );
    });

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.search = "";

      store.dispatch("ninios/setSearch2", "");
      emit("fetchTalla");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled
    };
  }
};
</script>
