<template>
  <!-- <div class="flex items-center justify-center"> -->
  <a-form
    :model="formState"
    :rules="rules"
    class="flex flex-wrap md:flex-nowrap gap-3 items-center text-center lg:w-1/2 mx-auto"
    ref="formRefFilter"
  >
    <div class="w-full md:w-2/3 lg:w-1/2 flex flex-col md:flex-row gap-3">
      <a-form-item class="mb-0 w-full" name="anio">
        <a-select v-model:value="formState.anio">
          <a-select-option value="">AÑOS</a-select-option>
          <a-select-option value="0">0 AÑOS</a-select-option>
          <a-select-option value="1">1 AÑOS</a-select-option>
          <a-select-option value="2">2 AÑOS</a-select-option>
          <a-select-option value="3">3 AÑOS</a-select-option>
          <a-select-option value="4">4 AÑOS</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item class="mb-0 w-full" name="meses">
        <a-select v-model:value="formState.meses">
          <a-select-option value="">MESES</a-select-option>
          <a-select-option value="0">0 MESES</a-select-option>
          <a-select-option value="1">1 MES</a-select-option>
          <a-select-option value="2">2 MESES</a-select-option>
          <a-select-option value="3">3 MESES</a-select-option>
          <a-select-option value="4">4 MESES</a-select-option>
          <a-select-option value="5">5 MESES</a-select-option>
          <a-select-option value="6">6 MESES</a-select-option>
          <a-select-option value="7">7 MESES</a-select-option>
          <a-select-option value="8">8 MESES</a-select-option>
          <a-select-option value="9">9 MESES</a-select-option>
          <a-select-option value="10">10 MESES</a-select-option>
          <a-select-option value="11">11 MESES</a-select-option>
        </a-select>
      </a-form-item>
    </div>

    <div class="w-full md:w-1/3 lg:w-1/2">
      <button @click="applyFilters" type="submit" class="text-center px-3 ml-2">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
  <!-- </div> -->
</template>
<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["fetchEdad"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);

    const formState = reactive({
      search: store.getters["ninios/search1"],
      anio: "",
      meses: ""
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          var search = toRaw(formState.anio) + ":" + toRaw(formState.meses);

          store.dispatch("ninios/setSearch1", search);
          emit("fetchEdad");
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {});

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.search = "";
      formState.anio = "";
      formState.meses = "";

      store.dispatch("ninios/setSearch1", "");
      emit("fetchEdad");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled
    };
  }
};
</script>
